import React, { Component } from "react";
import { AlertModal } from "../AlertModal/AlertModal";
import './SolicitacaoAdesao.css';

export const ModalIntrucoesPreenchimentoFinalidade =

class extends Component {
	render() {
		return <AlertModal
          titulo={this.props.titulo}
		      visible={this.props.visible}
          handleClose={this.props.handleClose}
        >
          <div className="container-body">
            <p>Observar as seguintes orientações ao preencher o campo finalidade.</p>
          </div>
        </AlertModal>
	}

}